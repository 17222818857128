
import { defineComponent } from "vue";
import * as Yup from "yup";
import ContentSpacer from "@/components/template/ContentSpacer.vue";
import FormButton from "@/components/template/elements/FormButton.vue";
import FormInput from "@/components/template/elements/FormInput.vue";
import LoginLayout from "@/layouts/LoginLayout.vue";
import Popup from "@/components/template/Popup.vue";
import TopLogo from "@/components/template/TopLogo.vue";

import pharmacyStore from "@/stores/pharmacy.store";

export default defineComponent({
    components: {
        ContentSpacer,
        FormButton,
        FormInput,
        Popup,
        LoginLayout,
        TopLogo
    },
    data () {
        return {
            mailRules: Yup.string()
                .required("Vous devez entrer un email.")
                .email("Vous devez entrer un email valide."),
            mail: "",
            password: ""
        };
    },
    methods: {
        async login () {
            await pharmacyStore.login({ mail: this.mail, password: this.password });
            this.$router.push({ name: "home" });
        }
    }
});
