<template>
    <div
        class="spacer"
        :class="size"
    />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        size: {
            type: String,
            validator: (value: string) => ["xs", "s", "m", "l", "xl"].includes(value),
            default: "m"
        }
    }
});
</script>

<style scoped lang="scss">
.spacer {
    &.xs {
        min-height: 4px;
    }
    &.s {
        min-height: 8px;
    }
    &.m {
        min-height: 16px;
    }
    &.l {
        min-height: 32px;
    }
    &.xl {
        min-height: 64px;
    }
}
</style>
