<template>
    <div class="login-layout">
        <div class="shadow">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>

<style scoped lang="scss">
.login-layout {
    background: var(--color-primary);

    .shadow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        min-height: 100vh;
        height: 100%;

        background: radial-gradient(hsla(0, 0, 0, 0), hsla(0, 0, 0, 0.1));
    }
}
</style>
