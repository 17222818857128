<template>
    <div
        class="button"
        tabindex="0"
        @click="$emit('click')"
        @keyup="onValidate"
    >
        <slot />
    </div>
</template>

<script>
export default {
    emits: ["click"],
    methods: {
        onValidate (event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                this.$emit("click");
            }
        }
    }
};
</script>

<style scoped lang="scss">
.button {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    padding: 0 64px;
    font-size: 24px;
    color: var(--color-text-light);
    cursor: pointer;
    border-radius: 64px;
    box-shadow:
        var(--color-shadow-soft) 5px 5px 10px,
        var(--color-light-hard) -5px -5px 10px;
    transition: ease-in-out 0.2s;

    &:hover {
        color: var(--color-primary);
        box-shadow:
            var(--color-shadow-soft) 3px 3px 6px,
            var(--color-light-hard) -3px -3px 6px;
    }

    &:focus {
        outline: none;
        border: 1px dotted var(--color-text-light);
    }
}
</style>
