<template>
    <div class="popup">
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>

<style scoped lang="scss">
.popup {
    background: var(--color-background);
    padding: 48px;
    border-radius: 32px;
    box-shadow: var(--color-shadow-soft) 10px 10px 20px,
    var(--color-light-soft) -10px -10px 20px;
}
</style>
