<template>
    <div class="top-logo">
        <img
            src="../../assets/logo.png"
            alt="Logo"
            class="logo"
        >
        <span class="text">ResaPharm</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>

<style scoped lang="scss">
.top-logo {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-lightest);
    padding: 8px;

    .logo {
        width: 32px;
        margin-right: 4px;
    }

    .text {
        font-size: 22px;
        font-weight: 600;
    }
}
</style>
