<template>
    <TopLogo />
    <LoginLayout>
        <Popup class="popup">
            <div class="form">
                <FormInput
                    v-model="mail"
                    input-id="mail"
                    class="form-input"
                    label="Email"
                    :rules="mailRules"
                />
                <FormInput
                    v-model="password"
                    input-id="password"
                    class="form-input"
                    label="Mot de passe"
                    type="password"
                    @submit="login"
                />
            </div>
            <ContentSpacer size="m" />
            <FormButton
                class="form-button"
                @click="login"
            >
                Connexion
            </FormButton>
        </Popup>
    </LoginLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as Yup from "yup";
import ContentSpacer from "@/components/template/ContentSpacer.vue";
import FormButton from "@/components/template/elements/FormButton.vue";
import FormInput from "@/components/template/elements/FormInput.vue";
import LoginLayout from "@/layouts/LoginLayout.vue";
import Popup from "@/components/template/Popup.vue";
import TopLogo from "@/components/template/TopLogo.vue";

import pharmacyStore from "@/stores/pharmacy.store";

export default defineComponent({
    components: {
        ContentSpacer,
        FormButton,
        FormInput,
        Popup,
        LoginLayout,
        TopLogo
    },
    data () {
        return {
            mailRules: Yup.string()
                .required("Vous devez entrer un email.")
                .email("Vous devez entrer un email valide."),
            mail: "",
            password: ""
        };
    },
    methods: {
        async login () {
            await pharmacyStore.login({ mail: this.mail, password: this.password });
            this.$router.push({ name: "home" });
        }
    }
});
</script>

<style scoped lang="scss">

.popup {
    text-align: center;
}

.logo {
    display: block;
    width: 96px;
    margin: auto auto 16px;
}

.form {
    display: flex;
    flex-direction: column;
    font-size: 24px;
}

.form-input {
    width: 400px;
}
</style>
