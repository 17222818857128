
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        size: {
            type: String,
            validator: (value: string) => ["xs", "s", "m", "l", "xl"].includes(value),
            default: "m"
        }
    }
});
